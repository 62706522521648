import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import Toast, { POSITION, useToast } from 'vue-toastification'
import { ToastOptionsAndRequiredContent } from 'vue-toastification/src/types'
import { createPinia } from 'pinia'
import { Field, Form } from 'vee-validate'

import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/nl'

import App from './App.vue'
import i18n from './locales'
import { router } from './router'

const pinia = createPinia()

const app = createApp(App)
app
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(VueGtag, {
    enabled: !!import.meta.env.VITE_GTAG_ID,
    config: {
      id: import.meta.env.VITE_GTAG_ID
    }
  }, router)
  .use(Toast, {
    transition: 'toast-animation',
    icon: false,
    position: POSITION.TOP_RIGHT,
    closeOnClick: false,
    draggable: false,
    timeout: 5000,
    toastClassName: 'toast-centered',
    filterBeforeCreate (toast: ToastOptionsAndRequiredContent, toasts: ToastOptionsAndRequiredContent[]) {
      const lastToast = toasts.at(-1)
      if (lastToast?.id && JSON.stringify(lastToast.content) === JSON.stringify(toast.content)) {
        useToast().dismiss(lastToast.id)
      }
      return toast
    }
  })

// components
app
  .component('ValidationForm', Form)
  .component('ValidationField', Field)

app.mount('#app')
