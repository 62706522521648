action:
  add_file: "Add document"
  validate: "Validate"
business_card:
  button: "Open the contact card"
  steps:
    android:
      first: "Press the Download button in the vcard file download popup."
      second: "Once the file is downloaded, press the Open button to save the contact."
    ios:
      first: "Open the downloaded file and tap the Contacts application icon."
      second: "The contact record opens, press the Save button to save the contact."
    safari:
      first: "Scroll to the bottom of the card and tap the New contact button."
      second: "The New Contact window opens, press the OK button to save the contact."
button:
  accept_all: "Accept all"
  back: "Back"
  continue: "Continue"
  cookies_policy: "cookie policy"
  customize: "Customize"
  discover_brand_offer: "About manager.one"
  download: "Download"
  reject_all: "Refuse all"
  save: "Save"
  send: "Send"
cookies:
  consent:
    description: "Like all sites, we use cookies to collect information about your use of the Smart card, with the aim of improving it. Visit our {cookiesPolicyLink} to find out more."
    title: "Cookies Policy"
  preferences:
    analytics:
      description: "Analytical cookies are used to understand how visitors interact with the website. These cookies help to provide information about the number of visitors, bounce rate, traffic source, etc."
      name: "Analytical (optional)"
    functional:
      description: "Functional cookies are necessary for the proper functioning of the website. These cookies provide the basic functionality and security features of the website in an anonymous manner."
      name: "Functional (required)"
    title: "Cookie preferences"
error:
  message: "Please rescan the card."
  subtitle: "An error has occurred."
  title: "Oops"
general:
  offered_by: "Offered by"
message:
  info:
    cookie:
      link: "/en/cookies"
  warning:
    form_not_valid: "Some information is missing or incorrect. Please check the fields indicated below"
    unknown: "An unknown error has occurred, if the problem persists, please contact customer support"
placeholder:
  mileage: "Enter mileage ..."
success:
  operation:
    update: "Your operation has been successfully registered"
table:
  amount: "Amount"
vcard:
  bred:
    presentation:
      title: "Discover the new Smart Card feature by BRED "
  macsf:
    presentation:
      title: "Discover the new Smart Card feature by MACSF.one"
  mone:
    presentation:
      title: "Discover the new Smart Card feature by manager.one "
  presentation:
    description: "The Smart Card feature uses the NFC technology integrated in the @:{'brand.name'} payment card. It allows the cardholder to easily share a virtual business card (vCard) or a personalised web link by simply scanning the card with an NFC-enabled phone."
    file:
      text: "This option allows you to share an image or video file, a Microsoft Word, Excel, Powerpoint or Adobe PDF document."
      title: "Share a file"
    link:
      text: "This option allows you to share, for example, a link to your LinkedIn profile, your company's website, a product catalogue, or a video."
      title: "Share a personalized website"
    virtual:
      text: "This option allows you to share a virtual business card containing all the information of the cardholder (contact details, email address, telephone number, social network profiles, etc.)"
      title: "Share your virtual business card"
  sbe:
    presentation:
      title: "Discover the new Smart Card feature by SBE"
