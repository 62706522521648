import { createI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/en'

import langDE from './de/de.yml'
import langEN from './en/en.yml'
import langFR from './fr/fr.yml'

const localStorageKeyLang = 'mone-client-locales'

export const translatedLanguages = [
  'fr',
  'en',
  'de'
]

// initialization
const langArray = (navigator.languages?.[0] || navigator.language).split('-')
let defaultLocale = langArray[0]
if (navigator.cookieEnabled) {
  const storedLanguage = window.localStorage.getItem(localStorageKeyLang)

  if (storedLanguage) {
    defaultLocale = storedLanguage
  }
}

const locale = translatedLanguages.includes(defaultLocale) ? defaultLocale : 'fr'

const i18n = createI18n({
  allowComposition: true,
  locale,
  fallbackLocale: 'en',
  globalInjection: true,
  legacy: false,
  messages: {
    en: langEN,
    fr: langFR,
    de: langDE
  },
  pluralizationRules: {
    fr: function (choice, choicesLength) {
      if (choicesLength === 2) {
        return choice > 1 ? 1 : 0
      }
      return choice ? Math.min(choice, 2) : 0
    },
    de: function (choice, choicesLength) {
      if (choicesLength === 2) {
        return choice > 1 ? 1 : 0
      }
      return choice ? Math.min(choice, 2) : 0
    }
  },
  silentTranslationWarn: true,
  missing: (locale, key, vm) => {
    let route = ''
    if (vm && typeof vm.appContext?.app?.config?.globalProperties?.$route?.path !== 'undefined') {
      route = ` - route: ${vm.appContext.app.config.globalProperties.$route.path}`
    }

    console.error(`missing translation: ${key} - locale: ${locale}${route}`)
  }
})

export default i18n

export const switchLocale = (locale: string) => {
  dayjs.locale(locale)
  window.localStorage.setItem(localStorageKeyLang, locale)
  document.documentElement.lang = locale
}

switchLocale(locale)

// @ts-ignore
export const globalTranslation = i18n.global.t
