import { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/default.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/pages/unknown/index.vue')
      },
      {
        path: ':uuid',
        name: 'details',
        component: () => import('@/pages/cards/CardWrapper.vue'),
        props: to => ({
          uuid: to.params.uuid
        })
      },
      {
        path: ':uuid/otapay',
        name: 'otapay',
        component: () => import('@/pages/Otapay.vue'),
        beforeEnter: (to, from, next) => {
          if (to.params.uuid === '99A97931-272A-4B1F-B902-75C50766CC72') {
            next()
          } else {
            next({ name: 'home' })
          }
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: { name: 'home' }
  }
]
